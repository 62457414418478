




























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Swiper from 'swiper';
import 'swiper/css/swiper.css'
import DataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';

// declare const window: any;
declare const kakao: any;

@Component
export default class PlaceMapModal extends mixins(DataFormat) {
  @Prop()
  public type!: any;

  @Prop()
  public list!: any;

  public swiper: any = null;
  public listIndex: any = 0;
  public map: any = null;
  public markers: any = [];
  public selectedMarker: any = null;

  get coordinate1(): any {
    if (this.type === 'place') {
      return this.list[this.listIndex].coordinate[0]
    } else {
      return this.list[this.listIndex].place.coordinate[0]
    }
  }
  get coordinate2(): any {
    if (this.type === 'place') {
      return this.list[this.listIndex].coordinate[1]
    } else {
      return this.list[this.listIndex].place.coordinate[1]
    }
  }
  get myLocation() {
    if (localStorage.nowLocation) {
      const geoCode = localStorage.nowLocation.split(',');
      return geoCode;
    }
  }

  public myLocationMove() {
    const marker = new kakao.maps.Marker({
      map: this.map,
      image: new kakao.maps.MarkerImage('/images/icon_mylocation.png', new kakao.maps.Size(22, 22)),
      position: new kakao.maps.LatLng(this.myLocation[0], this.myLocation[1])
    });
    this.map.panTo(new kakao.maps.LatLng(this.myLocation[0], this.myLocation[1]));
  }

  public mapMove() {
    this.map.panTo(new kakao.maps.LatLng(this.coordinate1, this.coordinate2));
    if (!this.selectedMarker || this.selectedMarker !== this.markers[this.listIndex]) {
      !!this.selectedMarker && this.selectedMarker.setImage(new kakao.maps.MarkerImage('/images/icon_map3.png', new kakao.maps.Size(25, 34)));
      this.markers[this.listIndex].setImage(new kakao.maps.MarkerImage('/images/icon_map3.png', new kakao.maps.Size(38, 52)));
      !!this.selectedMarker && this.selectedMarker.setZIndex(10);
      this.markers[this.listIndex].setZIndex(20);
    }
    this.selectedMarker = this.markers[this.listIndex];
  }
  public initMap() {
    const container = document.getElementById('map');
    if (container) {
      if (this.list.length > 0) {
        const coordinate1 = this.type === 'place' ? this.list[this.listIndex].coordinate[0] : this.list[this.listIndex].place.coordinate[0];
        const coordinate2 = this.type === 'place' ? this.list[this.listIndex].coordinate[1] : this.list[this.listIndex].place.coordinate[1];
        const options = {
          center: new kakao.maps.LatLng(coordinate1, coordinate2),
          level: 6,
        }
        const imageSrc = '/images/icon_map3.png';
        const imageSize = new kakao.maps.Size(25, 34);
        const seletedImageSize = new kakao.maps.Size(38, 52);
        const markerImg = new kakao.maps.MarkerImage(imageSrc, imageSize);
        const selectedMarkerImg = new kakao.maps.MarkerImage(imageSrc, seletedImageSize);
        // let selectedMarker: any = null;

        this.map = new kakao.maps.Map(container, options);

        this.list.forEach((item, index) => {
          const coordinate1 = this.type === 'place' ? item.coordinate[0] : item.place.coordinate[0];
          const coordinate2 = this.type === 'place' ? item.coordinate[1] : item.place.coordinate[1];
          const marker = new kakao.maps.Marker({
            map: this.map,
            image: markerImg,
            zIndex: 10,
            position: new kakao.maps.LatLng(coordinate1, coordinate2),
          });

          marker.setMap(this.map);
          this.markers.push(marker);
          this.mapMove();

          kakao.maps.event.addListener(marker, 'click', (i) => {
            const markerCoordinate1 = this.type === 'place' ? this.list[index].coordinate[0] : this.list[index].place.coordinate[0];
            const markerCoordinate2 = this.type === 'place' ? this.list[index].coordinate[1] : this.list[index].place.coordinate[1];
            this.map.panTo(new kakao.maps.LatLng(markerCoordinate1, markerCoordinate2));
            this.swiper.slideTo(index);
            if (!this.selectedMarker || this.selectedMarker !== marker) {
              !!this.selectedMarker && this.selectedMarker.setImage(markerImg);
              marker.setImage(selectedMarkerImg);
              !!this.selectedMarker && this.selectedMarker.setZIndex(10);
              marker.setZIndex(20);
            }
            this.selectedMarker = marker;
          })
        });
      } else if (this.list.id) {
        const coordinate1 = this.$route.name === 'place' ? this.list.coordinate[0] : this.list.place.coordinate[0];
        const coordinate2 = this.$route.name === 'place' ? this.list.coordinate[1] : this.list.place.coordinate[1];
        const options = {
          center: new kakao.maps.LatLng(coordinate1, coordinate2),
          level: 5
        }

        const imageSrc = '/images/icon_map3.png',
              imageSize = new kakao.maps.Size(38, 52);

        const marker = new kakao.maps.Marker({
          image: new kakao.maps.MarkerImage(imageSrc, imageSize),
          position: new kakao.maps.LatLng(coordinate1, coordinate2),
        });
        this.map = new kakao.maps.Map(container, options);
        marker.setMap(this.map);
      } else {
        const options = {
          center: new kakao.maps.LatLng(this.myLocation[0], this.myLocation[1]),
          level: 5
        }
        const imageSrc = '/images/icon_mylocation.png',
              imageSize = new kakao.maps.Size(22, 22);

        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(this.myLocation[0], this.myLocation[1]),
          image: new kakao.maps.MarkerImage(imageSrc, imageSize)
        });
        this.map = new kakao.maps.Map(container, options);
        marker.setMap(this.map);
      }
    }
  }

  @Watch('listIndex')
  public indexChange(newValue: number, oldValue: number) {
    if (newValue !== oldValue && this.map) {
      return [
        this.listIndex,
        this.mapMove()
      ]
    }
  }

  public activeSwiper() {
    this.swiper = new Swiper('.modal-place-map .swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      observer: true,
      observeParents: true,
    });
    this.swiper.on('slideChange', () => {
      this.listIndex = this.swiper.activeIndex;
    })
  }

  public modalOpened() {
    this.map = null;
    document.querySelector('html' as any).classList.add('open-modal');
    this.activeSwiper();
    if (kakao && kakao.maps) {
      this.initMap();
    }
  }
  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
    this.map = null;
    this.listIndex = 0;
    this.selectedMarker = null;
    this.markers = [];
    this.swiper = null;
  }
  public destroyed() {
    document.querySelector('html' as any).classList.remove('open-modal');
    this.map = null;
    this.listIndex = 0;
    this.selectedMarker = null;
    this.markers = [];
    this.swiper = null;
  }
}
